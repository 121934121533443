import { defineStore } from 'pinia'

interface RecommendedReadsResponse {
  data: Post[]
}

export const useRecommendedReadsStore = defineStore({
  id: 'recommendedReadsStore',
  state: () => ({
    data: [] as RecommendedReadsResponse['data'],
    loading: false,
    lastFetch: 0,
    error: null as string | null, // Added error state
  }),
  actions: {
    async fetch() {
      // Cache check - prevent refetch if data is less than 5 minutes old
      const CACHE_TIME = 5 * 60 * 1000; // 5 minutes
      if (this.data.length && Date.now() - this.lastFetch < CACHE_TIME) {
        return this.data;
      }

      try {
        this.loading = true;
        this.error = null; // Reset error before fetch
        const config = useRuntimeConfig();
        const baseUrl = config.public.CMS_PRODUCTION_BASE_URL || process.env.CMS_PRODUCTION_BASE_URL;

        const response = await $fetch<RecommendedReadsResponse>(`${baseUrl}/recommended-reads?populate=*`, {
          timeout: 10000, // Add timeout to prevent hanging requests
        });

        this.data = response.data;
        this.lastFetch = Date.now();
        return this.data;
      } catch (error) {
        console.error('Failed to fetch recommended-reads:', error);
        this.error = 'Failed to fetch recommended reads. Please try again later.'; // Set error message
        return []; // Return empty array on error to avoid breaking dependent components
      } finally {
        this.loading = false;
      }
    },
  },
});
